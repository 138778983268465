import Echo from "laravel-echo";
import Pusher from "pusher-js";
import { axiosInstance, getUser } from "../api/main";
declare global {
  interface Window {
    Pusher: any;
    Echo: any;
  }
}

const registerEcho = () => {
  window.Pusher = Pusher;

  window.Echo = new Echo({
    broadcaster: "reverb",
    key: process.env.REACT_APP_REVERB_APP_KEY,
    wsHost: process.env.REACT_APP_REVERB_HOST,
    wsPort: process.env.REACT_APP_REVERB_PORT,
    wssPort: process.env.REACT_APP_REVERB_PORT,
    forceTLS: (process.env.REACT_APP_REVERB_SCHEME ?? "https") === "https",
    enabledTransports: ["ws", "wss"],
    authEndpoint: `${process.env.REACT_APP_API_URL}/broadcasting/auth`,
    authorizer: (channel: any, options: any) => {
      return {
        authorize: (socketId: any, callback: any) => {
          axiosInstance
            .post(`${process.env.REACT_APP_API_URL}/broadcasting/auth`, {
              socket_id: socketId,
              channel_name: channel.name,
            })
            .then((response) => {
              callback(false, response.data);
            })
            .catch((error) => {
              callback(true, error);
            });
        },
      };
    },
  });
};

export { registerEcho };
