import React from "react";
import ReactDOM from "react-dom/client";
import homepageSlide from "./resources/images/welcome-carusel-baner.webp";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "bootstrap-icons/font/bootstrap-icons.css";
import { RouterProvider } from "react-router-dom";

import "./index.css";

import { createRouter } from "./app/routes/main";
import { registerEcho } from "./app/utils/wss_client";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createRouter();
registerEcho();

root.render(
  <React.StrictMode>
    <link
      rel="preload"
      {...({ fetchpriority: "high" } as any)}
      as="image"
      href={homepageSlide}
      type="image/webp"
    ></link>
    <RouterProvider router={router} />
  </React.StrictMode>
);
